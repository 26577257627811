<template>
  <div>
    <div class="header">
      <img
        src="../assets/images/new-sso-logo.svg"
        alt=""
        style="width: 260px;">
    </div>
    <div class="ssoWaiting">
      <p class="oneTitle">Waiting for audit</p>
      <el-divider></el-divider>
      <div class="wait ac"><img
        class="tvuImg"
        src="@/assets/images/waiting.png"
        alt=""
        width="42"></div>
      <p class="twoTitle four">Great! Thanks for setting up. </p>
      <p class="twoTitle four">We will send you an email at
        <strong>{{contactEmail}}</strong> when your TVU services can sign in with SSO. </p>
      <div class="operate">
        <el-button
          class="confirmBtn cursor"
          @click="routerPush('/singleSign')">{{$t('done')}}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Success',
  data () {
    return {
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      }
    }
  },
  computed: {
    contactEmail () {
      return this.$store.state.contactEmail
    }
  },
  methods: {
    routerPush (path) {
      ga('send', 'event', 'newUserService-ssoConfig-back', 'click')
      // this.$router.push(path)
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  height: 90px;
  width: 100%;
  background: -webkit-gradient(linear,left top,left bottom,from(#62d587),to(#33ab4f));
  background: linear-gradient(180deg,#62d587,#33ab4f);
  text-align: left;
  line-height: 90px;

  img {
    height: 90px;
    margin-left: 48px;
  }
}
.ssoWaiting {
  background: #FFFFFF;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 60px;
  text-align: left;

  .oneTitle {
    font-weight: 500;
    font-size: 36px;
    color: #252525;
  }

  .twoTitle {
    font-size: 16px;
    margin-top: 16px;
  }

  .operate {
    margin-top: 48px;
    text-align: right;
  }

  .confirmBtn {
    width: 101px;
    height: 41px;
    background: linear-gradient(241deg,#c1de00,#5fa808) hsla(0,0%,100%,.6);
    border-radius: 25px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
}
</style>
