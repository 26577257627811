<template>
  <div class="qr-code">
    <div class="qa-title">
      <img
        src="../assets/images/wx.png"
        alt="">
      <span>{{$t('wxDes')}}</span>
    </div>
    <div class="fs-24 fw">{{$t('wxTvu')}}</div>
    <div id="login_container"></div>
    <div
      class="back-btn"
      @click="back">
      <span class="border">{{$t('back')}}</span>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
export default {
  name: 'WxQrCode',
  data () {
    return {
      url: ''
    }
  },
  mounted () {
    this.qrCode()
  },
  computed: {
    wechatAppId () {
      return this.$store.state.wechatAppId
    }
  },
  methods: {
    qrCode () {
      if (getUrlParam('serviceName')) {
        // 获取跳转url
        let val
        const encodeHref = decodeURIComponent(window.location.href)
        val = encodeHref.substring(encodeHref.indexOf('?') + 1)
        if (val.includes('serviceName')) {
          val = val.split('url=')[1].split('#')[0].split('&serviceName=')
          this.url = val[0]
        }
        this.$store.commit('setWxServiceName', getUrlParam('serviceName'))
        this.$store.commit('setWxUrl', this.url)
      } else {
        this.$store.commit('setWxServiceName', '')
        this.$store.commit('setWxUrl', '')
      }
      const href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94ICN3eF9kZWZhdWx0X3RpcCB7CiAgZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveCAucXJjb2RlIHsKICB3aWR0aDogMTkwcHg7CiAgYm9yZGVyOiAwOwogIG1hcmdpbi10b3A6IDIwcHg7Cn0='
      const url = encodeURIComponent(window.location.origin + '/newloginservice#/wxlogin')
      var obj = new WxLogin({
        self_redirect: false,
        id: 'login_container',
        appid: this.wechatAppId,
        scope: 'snsapi_login',
        redirect_uri: url,
        state: Math.ceil(Math.random() * 1000),
        href: href
      })
      window.console.log(obj)
    },
    back () {
      this.$router.push('/signIn')
    }
  }
}
</script>

<style scoped lang="less">
.qr-code {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.back-btn {
  text-align: center;
  color: #47A325;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.qa-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #28282E;
  margin-top: 46px;
  margin-bottom: 8px;
  img {
    width: 24px;
    margin-right: 6px;
  }
}
#login_container {
  height: 320px;
}
</style>
