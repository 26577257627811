<template>
  <div class="verification-page">
    <h2 class="fs-24 marginB26">{{msgType == 1 ? $t('checkEmail') : $t('checkPhone')}}</h2>
    <div class="check-info">
      <span>{{$t('checkDes1')}}</span>
      <span>{{account}}{{$t('punctuation')}}</span>
      <span>{{msgType == 1 ? $t('checkDes2') : $t('checkDes3')}}</span>
    </div>
    <!-- 验证码输入框 -->
    <div class="code-wrap">
      <el-input
        class="code-paste"
        v-for="(item, index) in codeRefList"
        v-model="codeValue[index]"
        :key="index"
        maxlength="1"
        :min="0"
        :max="9"
        onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
        ref="codeRef"
        @input.native="(e) => changeCodeInput(e, index)"
        @keydown.native="(e) => keydownInput(e, index)"
        @paste.native="handlePaste($event)"
      >
      </el-input>
    </div>
    <input
      ref="hideInput"
      style="width: 0; height: 0; opacity: 0; border: 0"/>
    <!-- 图片验证码，忘记密码页面过来需要 -->
    <div
      class="flex-between code-content"
      v-if="flag">
      <el-input
        v-model="vcode"
        class="marginR10"
        tabindex='2'
        :placeholder="$t('vcodePlaceholder')"
        @keyup.enter.native="verifyRegister"></el-input>
      <div>
        <img
          :src="'data:image/jpeg;base64,'+imgUrl"
          alt=""
          @click='getVcode'>
      </div>
    </div>
    <div
      class="verify-btn"
      @click="verifyRegister">{{$t('verify')}}</div>
    <div class="ac light-grey fs-12 help-center cursor">
      <p>
        <span
          v-if="!showSendBtn"
          class="border"
          @click="sendCodeAgain">{{$t('sendCode')}}</span>
        <span
          v-if="showSendBtn"
          class='send-code border'
          @click='sendCodeAgain'>{{canSend ? $t('sendCode') : timeNum + 's'}}</span>
        <!--        <span class="marginL5">{{$t('findMore')}}</span>-->
      </p>
      <!--      <p>-->
      <!--        <span-->
      <!--          :class="$i18n.locale == 'en' ? '' : 'border' "-->
      <!--          @click="toHelpCenter">{{$t('information')}}</span>-->
      <!--      </p>-->
      <!--      <p>-->
      <!--        <span-->
      <!--          :class="$i18n.locale == 'en' ? 'border' : '' "-->
      <!--          @click="toHelpCenter">{{$t('helpCenter')}}</span>-->
      <!--      </p>-->
    </div>
  </div>
</template>

<script>
import { getUrlParam } from '@/assets/js/utils'
import { mapState } from 'vuex'
export default {
  name: 'VerificationPage',
  data () {
    return {
      msgType: 1,
      account: '',
      verifyCode: '',
      flag: false, // false 从注册页面过来 true 从忘记密码页面过来
      showSendBtn: false,
      timeNum: 59,
      timer: null,
      canSend: false,
      vcode: '',
      imgUrl: '',
      codeKey: '',
      codeRefList: [null, null, null, null, null, null],
      codeValue: [],
      domainName: ''
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    location () {
      return this.$store.state.location
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      tagKeys: 'tagKeyList',
      showPopup: 'showPopup'
    })
  },
  created () {
    this.flag = !Number(this.$route.query.verifyFlag)
    this.account = this.userInfo.account
    this.msgType = this.userInfo.msgType
    this.showSendBtn = this.userInfo.country == '86' && this.userInfo.msgType == '2'
    if (this.showSendBtn) {
      this.sendCodeChange()
    }
    this.$nextTick(() => {
      this.$refs.codeRef[0].focus()
    })
    if (this.flag) this.getVcode()
  },
  mounted () {
    // document.addEventListener('paste', (event) => {
    //   // console.log(event, '---')
    //   // if (!event.path[1].className.includes('code-paste')) return
    //   // 获取解析 粘贴的文本
    //   let text = (event.clipboardData || window.clipboardData).getData('text')
    //   if (!this.checkNumber(text)) return
    //   if (text.length > 1 && text.length < 7) {
    //     this.codeValue = text.split('').map(Number)
    //   } else {
    //     this.codeValue = text.substring(0, 6).split('').map(Number)
    //   }
    //   this.$nextTick(() => {
    //     this.$refs.hideInput.focus()
    //   })
    // })
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`
  },
  methods: {
    handlePaste (event) {
      let text = (event.clipboardData || window.clipboardData).getData('text')
      if (!this.checkNumber(text)) return
      if (text.length > 1 && text.length < 7) {
        this.codeValue = text.split('').map(Number)
      } else {
        this.codeValue = text.substring(0, 6).split('').map(Number)
      }
      this.$nextTick(() => {
        this.$refs.hideInput.focus()
      })
    },
    keydownInput (e, index) {
      const contentVal = this.$refs.codeRef[index].value
      if ((contentVal === null || contentVal === '' || contentVal === undefined) && e.keyCode === 8) {
        const isDelFirst = index === 0
        if (!isDelFirst) {
          this.$refs.codeRef[index - 1].focus()
        }
      }
    },
    checkNumber (String) {
      var reg = /^[0-9]+.?[0-9]*$/
      if (reg.test(String)) {
        return true
      }
      return false
    },
    changeCodeInput (e, index) {
      if (e.inputType == 'deleteContentBackward') {
        const isDelFirst = index === 0
        if (!isDelFirst) {
          this.$refs.codeRef[index - 1].focus()
        }
      } else {
        e.target.value = e.target.value.replace(/[^\d.]/g, '')
        let val = e.target.value
        if (val === '' || !this.checkNumber(val)) return
        const isLast = index === this.codeRefList.length - 1
        const refValue = this.$refs.codeRef[index + 1]
        if (isLast) {
          return this.$refs.codeRef[index].blur()
        }
        refValue.focus()
      }
    },
    // toHelpCenter () {
    //   window.open('https://www.figma.com/proto/bGs22XmuPdnoSX65Wh6hFo/LC-Hub-Concepts?node-id=277%3A12078&scaling=min-zoom&page-id=262%3A9230&starting-point-node-id=277%3A12078&show-proto-sidebar=1')
    // },
    sendCodeAgain () {
      let params = {
        account: this.account,
        msgType: this.msgType,
        eventType: this.flag ? 2 : 1,
        url: this.location,
        serverName: getUrlParam('serviceName')
      }
      if (this.userInfo.country == '86' && !this.canSend && this.userInfo.msgType == '2') return
      this.$axios.post(
        '/login-service/login/sendMsgCode', params).then((res) => {
        if (res.data.errorCode === '0x0') {
          if (this.userInfo.country == '86') {
            this.sendCodeChange()
          }
          this.$message({
            message: this.$t('sendSuccess'),
            type: 'success',
            duration: 3000
          })
        } else if (res.data.errorCode == '80945301') {
          this.$message({
            message: this.$t('error80945301'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80943301') {
          this.$message({
            message: this.$t('error80943201'),
            type: 'error',
            duration: 3000
          })
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch()
    },
    // 清除tagkey
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    verifyRegister () {
      this.verifyCode = this.codeValue.join('')
      if (!this.verifyCode) {
        return this.$message({
          message: this.$t('verifyCodeTip'),
          type: 'error',
          duration: 3000
        })
      }
      let params = {
        account: this.account,
        verifyCode: this.verifyCode
      }
      // tvu home邀请加入自己的组，用户注册时，需要携带inviteGroupId参数
      const keys = ['inviteGroupId']
      keys.forEach(key => {
        let value = getUrlParam(key)
        if (value !== null) {
          params[key] = value
        }
      })
      if (this.flag) {
        if (!this.vcode) {
          return this.$message({
            message: this.$t('verifyPicCodeTip'),
            type: 'error',
            duration: 3000
          })
        }
        params.imgCode = this.vcode
        params.key = this.codeKey
        this.$axios.post(
          '/login-service/login/verifyPasswordCode', params).then((res) => {
          if (res.data.errorCode == '0x0') {
            this.$store.state.emailCode = this.verifyCode
            // 重置密码，移动端需要携带参数
            const href = window.location.href
            const flag = href.includes('type=')
            if (flag) {
              // 移动端需要携带本来url上的参数 verifyFlag
              const url = '/changePsw?' + href.split('?verifyFlag=0')[1]
              this.$router.push({ path: url })
            } else {
              this.$router.push('/changePsw')
            }
            // this.$router.push('/changePsw')
          } else if (res.data.errorCode == '80943301') {
            this.getVcode()
            this.$message({
              message: this.$t('error80943201'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943402') {
            this.getVcode()
            this.$message({
              message: this.$t('error80943202'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943701') {
            this.getVcode()
            this.$message({
              message: this.$t('errorPic80943701'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943702') {
            this.getVcode()
            this.$message({
              message: this.$t('errorPic80943702'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943405') {
            this.getVcode()
            this.$message({
              message: this.$t('error809434051') + res.data.result + this.$t('error809434052'),
              type: 'error',
              duration: 3000
            })
          } else {
            this.getVcode()
            this.$message({
              message: res.data.errorInfo,
              type: 'error',
              duration: 3000
            })
          }
        }).catch()
      } else {
        if (this.location) {
          params.serverName = getUrlParam('serviceName')
          params.url = this.location
        }
        const href = window.location.href
        const flag = href.includes('type=')
        if (flag) params.type = href.split('type=')[1].split('&')[0]
        this.$axios.post(
          '/login-service/login/verifyRegisterCode', params).then((res) => {
          if (res.data.errorCode === '0x0') {
            if (res.data.result) {
              const data = res.data.result
              // 登录成功存储sid
              localStorage.setItem('email', data.email)
              localStorage.setItem('firstName', data.firstName)
              localStorage.setItem('userName', data.userName)
              localStorage.setItem('session', data.session)
              // media hub弹窗内登录成功之后，先将tagkey清除，再存储
              // if (this.showPopup) {
              //   this.clearTagKey()
              // }
              // 登录成功之后，先将tagkey清除，再存储
              this.clearTagKey()
              if (this.domainName) {
                window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                  data.tagList.forEach(itm => {
                    window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                  })
                }
              }
              // 此时设置sid有效
              this.$store.commit('changesValidSid', true)
              if (flag) {
                // 移动调用方法
                window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                  data.tagList.forEach(itm => {
                    window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                  })
                }
                // data.tvutag?window.document.cookie = "tvutag=" +data.tvutag+ ";path=/;domain=" + this.domainName + ";":"";
                const u = navigator.userAgent
                const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端

                if (isAndroid) { // 安卓机型
                  // getUserInfo(data.userId, data.session);
                  Android.getUserInfo(JSON.stringify({
                    userId: data.userId,
                    session: data.session,
                    firstName: data.firstName,
                    email: data.email
                  }))
                } else { // ios机型
                  window.webkit.messageHandlers.getUserInfo.postMessage(JSON.stringify({
                    userId: data.userId,
                    session: data.session,
                    firstName: data.firstName,
                    email: data.email
                  }))
                  // 通知gridlink登录成功
                  window.webkit.messageHandlers.hideNavIfLogin.postMessage(JSON.stringify({}))
                }
              } else {
                // loginWelcome为1跳到welcome页面
                if (data.loginWelcome == '1') {
                  this.$router.push('/welcome')
                } else {
                  window.top.location.href = data.redirectUrl
                }
              }
            } else {
              if (flag) {
                // 移动端需要携带本来url上的参数
                const url = '/signIn?' + href.split('?verifyFlag=1')[1]
                this.$router.push({ path: url })
              } else {
                this.$router.push('/signIn')
              }
              // this.$router.push('/signIn')
            }
          } else if (res.data.errorCode === '80943007') {
            this.$message({
              message: 'TVU administrator and support accounts must use \'Login with Google\' for access',
              type: 'warning',
              duration: 3000
            })
          } else if (res.data.errorCode === '80943201') {
            this.$message({
              message: this.$t('error80943201'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode === '80943202') {
            this.$message({
              message: this.$t('error80943202'),
              type: 'error',
              duration: 3000
            })
          } else {
            this.$message({
              message: res.data.errorInfo,
              type: 'warning',
              duration: 3000
            })
          }
        }).catch()
      }
    },
    sendCodeChange () {
      this.showCode = true
      this.canSend = false
      this.timeNum = 59
      this.timer = setInterval(() => {
        this.timeNum--
        if (this.timeNum == 0) {
          clearInterval(this.timer)
          this.canSend = true
        }
      }, 1000)
    },
    getVcode () {
      this.$axios.get(
        '/login-service/login/getVCode',
        {
          width: 80,
          height: 40
        }
      ).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.imgUrl = res.data.result.vCode
          this.codeKey = res.data.result.key
        } else {
          this.$message({
            message: this.$t('error80943601'),
            type: 'error',
            duration: 3000
          })
        }
      }).catch()
    }
  }
  // beforeDestroy () {
  //   document.removeEventListener('paste', () => {})
  // }
}
</script>

<style lang="less" scoped>
.check-info {
  color: #585B5D;
  margin-top: 97px;
  margin-bottom: 50px;
  line-height: 24px;
  text-align: center;
  word-break: break-word;
}

.verify-btn {
  background: #33AB4F;
  border-radius: 8px;
  width: 117px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  margin: 62px auto 70px;
  cursor: pointer;
}

.help-center {
  p {
    line-height: 18px;
  }
}

.code-wrap {
  display: flex;
  justify-content: center;
  /deep/ .el-input {
    width: 35px;
    margin-left: 8px;
  }

  /deep/.el-input__inner {
    width: 35px;
    height: 46px;
    line-height: 46px;
  }

  /deep/.el-input__inner {
    border: 1px solid #47A325BF
  }

  /deep/.el-input__inner {
    padding: 0;
    font-size: 16px;
    text-align: center;
  }
}

.code-content {
  padding: 20px 50px 0 58px;
}

</style>
