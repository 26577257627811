<template>
  <div class="singIn register">
    <div class="backIcon">
      <i
        v-if="isShowBack"
        class="el-icon-back cursor fs-22 marginB15"
        :class="isPc ? '' : 'mobile-back'"
        @click="goBack"></i>
      <h2 class="fs-24 marginB26">{{$t('resetPsw')}}</h2>
      <p class="gray marginB26">{{$t('resetPswInfo')}}</p>
    </div>
    <div class="formBody">
      <div class="singInForm">
        <div class="exchange pswArea marginB20">
          <el-input
            class="input"
            minlength="8"
            tabindex='1'
            type="password"
            maxlength="64"
            @keyup.enter.native="nextOpaertaion"
            @input="checkPsd"
            @focus="focusInput('psd')"
            @blur="blurInput"
            v-model="password"
            show-password
            :placeholder="$t('password')"></el-input>
          <div class="warning">
            <i class="el-icon-warning-outline"></i>
            <span class="warningTip">{{$t('pswtip')}}</span>
          </div>
        </div>
        <div class="exchange comfirmarea marginB40">
          <el-input
            class="input"
            minlength="8"
            tabindex='2'
            type="password"
            maxlength="64"
            @keyup.enter.native="nextOpaertaion"
            @focus="focusInput"
            @blur="blurInput"
            v-model="comfirmPassword"
            show-password
            :placeholder="$t('comfirmPsw')"></el-input>
          <div class="warning">
            <i class="el-icon-warning-outline"></i>
            <span class="warningTip">{{$t('pswInconsist')}}</span>
          </div>
        </div>
        <div class="fr">
          <el-button
            class="sign-btn"
            v-repeat-click="{event:nextOpaertaion}"
            :disabled='isDisabeBtn'
            :loading="isLoading">{{$t('done')}}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUrlParam } from '@/assets/js/utils'
const sha512 = require('js-sha512')

export default {
  name: 'CreateAccount',
  data () {
    return {
      password: '',
      comfirmPassword: '',
      isShowStrength: false,
      strengthNum: 0,
      isDisabeBtn: true,
      emailErrorTip: '',
      isLoading: false,
      fromUnlock: false,
      isShowBack: true
    }
  },
  watch: {
    password: function (newVal) {
      if (!newVal || !this.comfirmPassword) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    },
    comfirmPassword: function (newVal) {
      if ($('.comfirmarea').hasClass('errorInput')) {
        $('.comfirmarea').removeClass('errorInput')
      }
      if (!newVal || !this.password) {
        this.isDisabeBtn = true
      } else {
        this.isDisabeBtn = false
      }
    }

  },
  computed: {
    emailCode () {
      return this.$store.state.emailCode
    },
    userInfo () {
      return this.$store.state.userInfo
    },
    isPc () {
      return this.$store.state.isPc
    }
  },
  created () {
    if (getUrlParam('email')) {
      this.fromUnlock = getUrlParam('fromPage') == 'unlock'
      this.$store.state.userInfo.account = getUrlParam('email')
      this.$store.state.emailCode = getUrlParam('activeCode')
      this.isShowBack = false
    }
  },
  methods: {
    goBack () {
      ga('send', 'event', 'newUserService-changePsw-goBack', 'click')
      this.$router.go(-1)
    },
    nextOpaertaion () {
      ga('send', 'event', 'newUserService-changePsw-changepsw', 'click')
      if (this.isLoading) {
        return
      }
      if (!this.checkPsd(this.password)) {
        $('.pswArea').addClass('errorInput').find('.warning').removeClass('info')
        return
      }
      if (this.password != this.comfirmPassword) {
        $('.comfirmarea').addClass('errorInput')
        return
      }
      if (this.password.length < 8) {
        this.$message({
          message: this.$t('pswtip'),
          type: 'error',
          duration: 3000
        })
        return
      }
      this.isLoading = true
      let params = {
        account: this.userInfo.account,
        password: sha512(this.password),
        confirmPassword: sha512(this.comfirmPassword),
        verifyCode: this.emailCode
      }
      let url = ''
      if (this.fromUnlock) {
        url = '/login-service/login/resetPasswordFromUnLock'
      } else {
        url = '/login-service/login/resetPassword'
      }
      this.$axios.post(url, params).then((res) => {
        this.isLoading = false
        if (res.data.errorCode == '0x0') {
          const params = new URLSearchParams(location.search.slice(1))

          if (params.get('email')) {
            location.href = location.href.replace(/&email=.+&/, '&').replace(/&activeCode=.+#/, '#').replace('/changePsw', '/')
          } else {
            const href = window.location.href
            const flag = href.includes('type=')
            if (flag) {
              // 移动端需要携带本来url上的参数
              const url = '/signIn?' + href.split('?')[1]
              this.$router.push({ path: url })
            } else {
              this.$router.push('/')
            }
            // this.$router.push({ path: '/' })
          }
        } else if (res.data.errorCode == '80943501') {
          this.$message({
            message: this.$t('error80943201'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80943502') {
          this.$message({
            message: this.$t('error80943202'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80943503') {
          this.$message({
            message: this.$t('pswInconsist'),
            type: 'error',
            duration: 3000
          })
        } else if (res.data.errorCode == '80943504') {
          this.$message({
            message: this.$t('error80943504'),
            type: 'error',
            duration: 3000
          })
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(() => {
        this.isLoading = false
      })
    },
    focusInput (event) {
      if (event && event == 'psd') {
        if (!this.checkPsd(this.password) && !$('.pswArea').hasClass('errorInput')) {
          $('.pswArea').removeClass('errorInput').find('.warning').addClass('info')
          $('.pswArea').find('.tip').css({
            display: 'inline-block',
            color: 'rgba(51,171,79,1)'
          })
        }
      } else {
        $(event.target).parent().prev('.tip').css({
          display: 'inline-block',
          color: 'rgba(51,171,79,1)'
        })
      }
    },
    blurInput () {
      $(event.target).parent().prev('.tip').css('color', 'rgba(153,153,153,1)')
    },
    checkPsd (val) {
      const reUpper = /[A-Z]/
      const reLower = /[a-z]/
      const reNum = /[0-9]/
      const reLen = /^.{8,64}$/
      const patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im
      let count = 0

      if (reLower.test(val)) {
        ++count
      }
      if (reUpper.test(val)) {
        ++count
      }
      if (reNum.test(val)) {
        ++count
      }
      if (patrn.test(val)) {
        ++count
      }
      if (reLen.test(val)) {
        ++count
      }
      if (count == 5) {
        $('.pswArea').removeClass('errorInput').find('.warning').removeClass('info')
        return true
      }
      if ($('.pswArea').hasClass('errorInput')) {
        $('.pswArea').find('.warning').removeClass('info')
      } else {
        $('.pswArea').find('.warning').addClass('info')
      }
      return false
    }
  }
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     const params = new URLSearchParams(location.search.slice(1))
  //
  //     if (params.get('email')) {
  //       vm.$store.state.userInfo.account = params.get('email')
  //       vm.$store.state.emailCode = params.get('activeCode')
  //     }
  //   })
  // }
}
</script>
<style lang="less" scoped>
.exchange .warning {
  display: none;
  .warningTip, i {
    color: #d93025;
  }
}

.errorInput {
  /deep/.el-input__inner {
    border: 1px solid #d93025
  }
}

.warning {
  margin-top: 10px;
  &.info {
    display: inline-block !important;
    .warningTip {
      color: #505050 !important;
    }
    .el-icon-warning-outline {
      color: #505050 !important;
    }
  }
}
.sign-btn {
  padding: 13px 30px!important;
  background-color: #33AB4F;
  border: none;
  color: #FFFFFF;
}
.errorInput .warning {
  display: block !important;
}
</style>
