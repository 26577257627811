<template>
  <div
    class="ssoConfig"
    :class="{ 'ssoConfig-mobile': !isPc }">
    <el-form
      class="configForm"
      :class="{ 'config-mobile': !isPc }"
      :rules="ruleForm"
      ref="ruleForm"
      label-position="top"
      label-width="80px"
      :model="formLabelAlign">
      <p class="fs-28 dark-black fw marginB10">SSO SAML</p>
      <p class="twoTitle">Okta and OneLogin available for Enterprise TVU subscribers.</p>
      <el-divider></el-divider>
      <el-form-item
        label="Corporation name"
        prop="name">
        <el-input
          type='text'
          v-model="formLabelAlign.name"
          maxlength="128"></el-input>
      </el-form-item>
      <el-form-item
        label="Contact email address"
        prop="email">
        <el-input
          v-model="formLabelAlign.email"
          maxlength="50"></el-input>
      </el-form-item>
      <el-divider></el-divider>
      <p class="twoTitle three marginB20">What IdP do you use?</p>
      <p class="twoTitle four marginB20">Because each IdP requires different information for setup,we’ll first need you to tell us more information about what SAML IdP you are using. Then your users can enforce SSO.</p>
      <el-form-item
        label="Select your SAML IdP"
        prop="samlIdP">
        <el-radio-group v-model="formLabelAlign.samlIdP">
          <el-radio label="okta">OKTA</el-radio>
          <br>
          <el-radio
            style='margin-top: 15px;'
            label="OneLogin">OneLogin</el-radio>
          <br>
          <el-radio
            style='margin-top: 15px;'
            label="Microsoft">Microsoft Azure</el-radio>
        </el-radio-group>
      </el-form-item>
      <div style='position: relative'>
        <el-form-item
          label="Please enter your company’s domain here.">
          <span class='domain-icon'>*</span>
          <el-input
            class='domain-input'
            v-for='(item, index) in domainsList'
            :key='index'
            v-model='domainsList[index]'
            maxlength="128">
            <i
              v-if='index != 0'
              @click='deleteDomain(index)'
              slot="suffix"
              class="el-icon-circle-close"></i>
          </el-input>
          <el-button
            class='add-domain'
            :class="{'add-domain-mobile': !isPc}"
            plain
            @click='addDomain'
            type='success'>+ Add domain</el-button>
        </el-form-item>
      </div>
      <p class="twoTitle six fw marginB20">{{providerTitle}}</p>
      <p class="twoTitle four marginB20">To configure SSO for TVU, please set up TVU service as an application within your IdP. With {{providerTitle}} this is easy! Search for the TVU application and our information will pre-fill.</p>
      <p class="twoTitle four marginB20">Once you’ve set up the application, please send us the following information and we’ll get things hooked up on our side:</p>
      <p class="twoTitle four">- {{providerLabel}}</p>
      <p class="twoTitle five">- {{providerSubLabel}}</p>
      <p class="twoTitle five marginB20">- SAML Certificate{{formLabelAlign.samlIdP == 'Microsoft' ? '(Base64)' : ''}}</p>
      <el-form-item
        :label="providerLabel"
        prop="ssoUrl">
        <el-input
          type='text'
          v-model="formLabelAlign.ssoUrl"
          maxlength="128"></el-input>
      </el-form-item>
      <el-form-item
        :label="providerSubLabel"
        prop="entityID">
        <el-input
          type='text'
          v-model="formLabelAlign.entityID"
          maxlength="128"></el-input>
      </el-form-item>
      <el-form-item
        :label="formLabelAlign.samlIdP == 'Microsoft' ? 'SAML Certificate(Base64)' : 'SAML Certificate'"
        prop="certificate">
        <el-input
          :rows="5"
          type="textarea"
          v-model="formLabelAlign.certificate"></el-input>
      </el-form-item>
      <div class="operate">
        <span
          class="back cursor"
          @click="routerPush('/singleSign')">Back</span>
        <el-button
          class="confirmBtn cursor"
          @click="nextOperation">Next</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'SSOConfigPage',
  data () {
    return {
      formLabelAlign: {
        name: '',
        email: '',
        samlIdP: 'okta',
        domains: '',
        ssoUrl: '',
        entityID: '',
        certificate: ''
      },
      domainsList: ['']
    }
  },
  computed: {
    providerTitle () {
      return this.getProviderLabel('title')
    },
    providerLabel () {
      return this.getProviderLabel('label')
    },
    providerSubLabel () {
      return this.getProviderLabel('subLabel')
    },
    ruleForm () {
      return {
        name: [{ required: true, message: 'Please enter Corporation name', trigger: 'blur' }],
        email: [{ required: true, message: 'Please enter Contact email address', trigger: 'blur' },
          { type: 'email', message: 'Contact email address fromat error', trigger: 'blur' }],
        samlIdP: [{ required: true, message: 'Please enter SAML IdP', trigger: 'change' }],
        // domains: [{ required: true, message: 'Please enter company’s domain', trigger: 'blur' }],
        ssoUrl: [{ required: true, message: 'Please enter ' + this.providerLabel, trigger: 'blur' }],
        entityID: [{ required: true, message: 'Please enter ' + this.providerSubLabel, trigger: 'blur' }],
        certificate: [{ required: true, message: this.formLabelAlign.samlIdP == 'Microsoft' ? 'Please enter SAML Certificate(Base64)' : 'Please enter SAML Certificate', trigger: 'blur' }]
      }
    },
    ...mapState({
      isPc: 'isPc'
    })
  },
  methods: {
    getProviderLabel (type) {
      const providerLabels = {
        okta: {
          title: 'Okta',
          label: 'SAML Okta URL',
          subLabel: 'SAML IDP EntityID'
        },
        OneLogin: {
          title: 'OneLogin',
          label: 'SAML Single Sign-On URL (SAML 2.0 Endpoint)',
          subLabel: 'SAML Issuer URL'
        },
        Microsoft: {
          title: 'Microsoft Azure',
          label: 'SAML Login URL',
          subLabel: 'SAML Azure AD Identifier'
        }
      }

      const currentProvider = providerLabels[this.formLabelAlign.samlIdP] || {}
      return currentProvider[type] || ''
    },
    addDomain () {
      this.domainsList.push('')
    },
    deleteDomain (index) {
      this.domainsList.splice(index, 1)
    },
    nextOperation () {
      const domainsList = this.domainsList.filter(item => {
        return item && item.trim()
      })
      if (domainsList && domainsList.length < 1) {
        return this.$message.error('Please enter company’s domain')
      }
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return
        }
        this.$axios.post('/userGroup/user-group/login/saveSSOConfig',
          {
            companyName: this.formLabelAlign.name.trim(),
            email: this.formLabelAlign.email.trim(),
            platform: this.formLabelAlign.samlIdP.trim(),
            ssoUrl: this.formLabelAlign.ssoUrl.trim(),
            entityId: this.formLabelAlign.entityID.trim(),
            companyDomain: domainsList.join(','),
            certificate: this.formLabelAlign.certificate.trim()
          }
        ).then((res) => {
          // 跳转code页面
          if (res.data.errorCode == '0x0') {
            this.$store.commit('changeContactEmail', this.formLabelAlign.email)
            // this.$router.push('/waitingPage')
            this.routerPush('/waitingPage')
          }
        }).catch()
      })
    },
    routerPush (path) {
      // ga('send', 'event', 'newUserService-ssoConfig-back', 'click')
      // this.$router.push(path)
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ssoConfig {
  background: #F6F7F9;
  padding: 60px 0;
}

.configForm {
  background: #ffffff;
  width: 545px;
  margin: 0 auto;
  padding: 15px 60px;
}

.operate {
  margin-top: 48px;
  text-align: right;
  height: 40px;
  line-height: 40px;
}

/deep/.confirmBtn {
  width: 101px;
  height: 41px;
  background: linear-gradient(241deg, #c1de00, #5fa808) hsla(0, 0%, 100%, .6);
  border-radius: 25px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.back {
  font-size: 16px;
  color: #33ab4f;
  margin-right: 32px;
}

/deep/.el-input__inner, /deep/.el-textarea__inner {
  width: 70%;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #33ab4f;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #33ab4f;
  background: #33ab4f;
}

/deep/.el-textarea__inner:focus {
  border-color: #33ab4f;
}

.add-domain {
  position: absolute;
  top: 3px;
  right: -30px;
  z-index: 9;
}

.domain-input {
  margin-bottom: 10px;
}

/deep/.el-input__suffix {
  top: 5px;
  cursor: pointer;
  font-size: 16px;
  right: 100px;
}

.domain-icon {
  color: red;
  position: absolute;
  left: -10px;
  top: -5px;
}

/deep/.el-button {
  padding: 12px 20px;
}

// 兼容移动端UI
.ssoConfig-mobile {
  background: #ccc;
  padding: 0;

  /deep/.el-input__inner, /deep/.el-textarea__inner {
    width: 100%;
  }

  /deep/.el-input__suffix {
    right: 10px;
  }

  .config-mobile {
    width: 100%;
    padding: 12px 24px;
  }

  .add-domain-mobile {
    position: static;
  }

  .domain-input {
    /deep/.el-input__inner {
      width: 88%;
    }
  }
}
</style>
