<template>
  <div class="unlocked">
    <div class="fs-18 email-invalid">{{$t('invalidEmail')}}</div>
    <div class="unlocked-des">{{$t('accountUnlocked')}}</div>
    <div class="btn">
      <el-button
        class="sign-btn cursor"
        @click="toLogin">{{$t('signIn')}}</el-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/assets/js/vueBus'
export default {
  name: 'UnlockedSuccess',
  created () {
  },
  methods: {
    toLogin () {
      Bus.$emit('canClearSid')
      this.$router.push('/signIn')
    }
  }
}
</script>

<style lang="less" scoped>
.email-invalid {
  font-weight: bold;
}

.unlocked-des {
  color: #505050;
  font-size: 16px;
  border-top: 1px solid #F5F5F5;
  margin-top: 12px;
  padding: 16px 0;
}

.btn {
  overflow: hidden;
}

.sign-btn {
  padding: 13px 30px!important;
  background-color: #33AB4F;
  border: none;
  color: #FFFFFF;
  margin-top: 50px;
  float: right;
}
</style>
