import { render, staticRenderFns } from "./SSOConfigPage.vue?vue&type=template&id=00c9ff53&scoped=true&"
import script from "./SSOConfigPage.vue?vue&type=script&lang=js&"
export * from "./SSOConfigPage.vue?vue&type=script&lang=js&"
import style0 from "./SSOConfigPage.vue?vue&type=style&index=0&id=00c9ff53&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c9ff53",
  null
  
)

export default component.exports